@import "./Consts.scss";

.about {
  max-width: $pg-md;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;
  margin-bottom: 85px;

  font-size: 18px;
  line-height: 27px;
  padding: 20px;

  @media (max-width: $width-sm) {
    line-height: 26px;
    font-size: 17px;
  }
  @media (max-width: $width-xs) {
    font-size: 16px;
  }

  a {
    // color: blue;
    font-weight: 600;
    color: black;
    text-decoration: none;
    // border-bottom: 2px solid black;
  }
  a:hover {
    color: blue;
  }
}
