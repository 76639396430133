// Break points
$width-lg: 1000px;
$width-md: 800px;
$width-sm: 500px;
$width-xs: 320px;
$width-xxs: 280px;

// Paragraph width
$pg-xlg: 1300px;
$pg-lg: 670px;
$pg-md: 520px;

.title {
  max-width: $pg-lg;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
  font-size: 48px;
  margin-bottom: 20px;
  color: #aaa;
}

.selection-disabled {
  // // Disable text selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selection-color-update {
  ::-moz-selection {
    /* Code for Firefox */
    background: blue;
    color: white;
  }
  ::selection {
    background: blue;
    color: white;
  }
}
