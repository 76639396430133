@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./Consts.scss";

body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Inter", sans-serif;
  margin: 0px;

  // @extend .selection-color-update;
}

.intro-text {
  position: relative;
  font-size: 62px;
  font-weight: 300;
  // max-width: $pg-lg;
  max-width: 820px;
  margin-top: 185px;
  margin-bottom: 135px;
  letter-spacing: -1px;
  text-align: left;
  // background-color: red;

  margin-left: auto;
  margin-right: auto;
  padding: 20px;

  span {
    // font-weight: 500;
  }

  @media (max-width: $width-lg) {
    font-size: 52px;
    margin-bottom: 110px;
  }
  @media (max-width: $width-md) {
    font-size: 46px;
    margin-top: 130px;
    margin-bottom: 100px;
  }
  @media (max-width: $width-sm) {
    font-size: 38px;
    margin-top: 100px;
    margin-bottom: 60px;
  }
  @media (max-width: $width-xs) {
    font-size: 32px;
    margin-top: 90px;
    margin-bottom: 50px;
  }
  @media (max-width: $width-xxs) {
    font-size: 28px;
    margin-top: 70px;
    margin-bottom: 30px;
  }
}

/* FOOTER */
.footer {
  position: relative;
  width: 100%;
  left: 0px;
  height: 160px;
  bottom: 0px;
  margin: 0px;
  margin-top: 100px;
  // background-color: #f7f7f7;
  padding-top: 10px;
  // border-top: 1px solid #eee;
}
.footer > .footer-social {
  max-width: 420px;
  width: 80%;
  // background-color: red;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: $width-sm) {
    // max-width: 240px;
  }
  @media (max-width: $width-xxs) {
    // max-width: 220px;
  }
}
.footer-social > .social {
  font-weight: 500;
  font-size: 15px;
  border-bottom: 2px solid #333;
  cursor: pointer;
  a {
    text-decoration: none;
    color: black;
  }
  @media (max-width: $width-sm) {
    font-size: 14px;
  }
  @media (max-width: $width-xxs) {
    font-size: 13px;
  }
}
.footer-social > .social:hover {
  color: blue;
  border-color: blue;

  a {
    text-decoration: none;
    color: blue;
  }
}
