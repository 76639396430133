@import "./Consts.scss";

$line-gap: 12px;

.portfolio {
  max-width: $pg-xlg;
  margin: 0px auto;
  padding: 0px 20px;
  margin-bottom: 100px;

  display: block;
  position: relative;
}

.project {
  display: inline-block;
  vertical-align: top;
  width: calc(33.33% - 2px);
  border-left: 1px solid #333;
  margin-top: $line-gap * 3;

  .project-content {
    width: calc(100% - 2 * $line-gap);
    margin-left: $line-gap;
    height: 100%;
  }
  .project-content .tag {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 7px 0px;
    font-size: 13px;
    color: #333;
    @media (max-width: $width-xxs) {
      font-size: 12px;
    }
  }
  .project-content .hed {
    padding-top: 16px;
    font-size: 20px;
    cursor: pointer;
    @extend .selection-disabled;

    @media (max-width: $width-xs) {
      font-size: 18px;
    }
    @media (max-width: $width-xxs) {
      font-size: 17px;
    }
  }
  .project-content .hed:hover {
    span {
      color: blue;
      transition: all 100ms;

      .arrow {
        // margin-left: 8px;
        transition: all 100ms;
      }
    }
  }

  .project-content img {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
  }
  .project-content img:hover {
    width: calc(100% - 4px);
    border: 2px solid blue;
    transition: all 0.1s;
  }

  .desc {
    opacity: 0;
    overflow: hidden;
    display: block;
    height: 0px;
    transition: height 300ms;
    font-size: 14px;
    line-height: 20px;

    .bolden {
      font-weight: bold;
    }
    @media (max-width: $width-lg) {
      line-height: 20px;
    }
  }
  .showDesc {
    height: 110px;
    padding-top: 16px;
    opacity: 1;
  }

  .arrow {
    cursor: pointer;
    display: inline-block;
    transition: transform 0.3s;
  }
  .rotateArrow {
    transform: rotate(90deg);
  }

  @media (max-width: 1000px) {
    width: calc(50% - 2px);
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}
